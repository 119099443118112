<template>
  <div id="excel-phone">
    <div style="padding:5px;">
      <div class="infinite-list" v-infinite-scroll="load" style="overflow:auto;height: 93vh;"
           :infinite-scroll-distance="10">
        <!-- 下拉刷新组件 -->
        <van-grid :column-num="2">
          <van-grid-item
              v-for="value in 12"
              :key="value"
              icon="photo-o"
              text="文字">
            <van-row>
              <van-col span="4">
                <van-image width="20"
                           height="20"
                           :src="require('@/assets/icon/bid.png')">
                </van-image>
              </van-col>
              <van-col span="20">
                <span>月管理费用</span>
              </van-col>
            </van-row>


            <van-field
                v-model="username"
                input-align="center"
                right-icon="edit"
            />
          </van-grid-item>
        </van-grid>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExcelPhone",
  data() {
    return {
      username: 11111,
      show: false,
      list: [
          {}
      ],
      value: 50,
      columns: ['财务科', '法规科', '基建科', '安全科', '教育办'],
      showPicker: false,
      phone: '',
      wexin: '',
      tuifen: '',
      uploader: [],
      currentPage: 0,
      showPopover: false,
      actions: [{
        text: '编辑'
      }, {
        text: '删除'
      }],
      isLoading: false,
    }
  },
  mounted() {
  },
  methods: {
    // 无限滚动
    load() {
      let obj = {
        showPopover: false,
      }
      console.log('load');
      this.list.push(obj)
    },
    // 下拉刷新
    onRefresh() {
      let that = this
      setTimeout(() => {
        vant.Toast('刷新成功');
        that.isLoading = false;
      }, 1000);
    },
    // 设置
    setUp(action, index) {
      console.log(action.text, 'action');
      if (action.text == '编辑') {
        this.show = true
      }
      if (action.text == '删除') {
        vant.Dialog.confirm({
          message: '此操作将会删除该条数据，是否继续操作',
        })
            .then(() => {
              vant.Notify({
                type: 'success',
                message: '删除成功！'
              });
            })
            .catch(() => {
            });
      }
    },
    // 编辑
    edit() {
      console.log(this.uploader);
    },
    // 选择
    onConfirm(value) {
      this.value = value;
      this.showPicker = false;
    },
    // 返回
    onClickLeft() {
      window.history.back()
    },
    // 新增
    onClickRight() {
      console.log('add');
      this.show = true
    }
  }
}
</script>

<style scoped>
body {
  height: 100%;
}

#excel-phone {
  height: 100vh;
  background-color: #f1f1f1;
  position: relative;
}

.van-popover__action {
  width: 100% !important;
}

.box {
  margin-bottom: 5px;
}

.title {
  font-size: 12px;
  color: #A4A4A4;
  margin: 5px 0;
}

.info {
  font-size: 14px;
}
</style>